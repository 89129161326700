import React, {Component} from 'react';
import Content from "../../common/containers/Content";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import PrivatePage from "../../common/containers/PrivatePage";
import Waiting from "../../common/containers/Waiting";
import Ajax from "../../common/ajax";
import Config from "../../config";

class PaidDocumentsReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: true,
            statistics: {},
        };
    }

    componentDidMount() {
        this.fetchStatistics();
    }

    fetchStatistics() {
        Ajax.get(`${Config.djangoHost}r/docStatistics`).done(statistics => {
            this.setState(state => ({
                ...state,
                statistics,
            }));
        }).always(() => {
            this.setState(state => ({
                ...state,
                processing: false,
            }));
        });
    }

    render() {
        const {
            max_delta_days,
            min_delta_days,
            mean_delta_days,
            median_delta_days,
            std_delta_days,
            var_delta_days,
            mean_delta_months,
            max_page_count,
            min_page_count,
            mean_page_count,
            median_page_count,
            std_page_count,
            var_page_count,
            frequent_users,
            frequent_amount,
            counter_per_price,
            counter_per_authors,
        } = this.state.statistics;

        return (
            <PrivatePage title="Paid Documents Report">
                <Content>
                    <Waiting isProcessing={this.state.processing}>
                        <Row>
                            <Col md={6}>
                                <table className="table table-striped table-condensed" border="1">
                                    <tbody>
                                    <tr>
                                        <td>Editing time span</td>
                                        <td>
                                            <b>Days</b>
                                            <br/>
                                            Max: {max_delta_days}
                                            <br/>
                                            Min: {min_delta_days}
                                            <br/>
                                            Mean: {mean_delta_days}
                                            <br/>
                                            Median: {median_delta_days}
                                            <br/>
                                            Std: {std_delta_days}
                                            <br/>
                                            Var: {var_delta_days}
                                            <br/>

                                            <b>Months</b>
                                            <br/>
                                            Mean: {mean_delta_months}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Length (pages)</td>
                                        <td>
                                            Max: {max_page_count}
                                            <br/>
                                            Min: {min_page_count}
                                            <br/>
                                            Mean: {mean_page_count}
                                            <br/>
                                            Median: {median_page_count}
                                            <br/>
                                            Std: {std_page_count}
                                            <br/>
                                            Var: {var_page_count}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <h3>20 most frequent payers</h3>
                        <Row>
                            <Col md={6}>
                                <table className="table table-striped table-condensed">
                                    <tbody>
                                    {frequent_users && frequent_users.map(i => (
                                        <tr key={i[0]}>
                                            <td>{i[0]}</td>
                                            <td>{i[1]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <h3>Frequency of checkouts per user</h3>
                        <Row>
                            <Col md={6}>
                                <table className="table table-striped table-condensed">
                                    <tbody>
                                    <tr>
                                        <td>Number of Checkouts</td>
                                        <td>Frequency</td>
                                    </tr>
                                    {frequent_amount && frequent_amount.map(i => (
                                        <tr key={i[0]}>
                                            <td>{i[0]}</td>
                                            <td>{i[1]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <h3>Frequency of checkouts per plans</h3>
                        <Row>
                            <Col md={6}>
                                <table className="table table-striped table-condensed">
                                    <tbody>
                                    <tr>
                                        <td>Plan</td>
                                        <td>Frequency</td>
                                    </tr>
                                    {counter_per_price && counter_per_price.map(i => (
                                        <tr key={i[0]}>
                                            <td>{i[0]}</td>
                                            <td>{i[1]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <h3>Frequency of authors</h3>
                        <Row>
                            <Col md={6}>
                                <table className="table table-striped table-condensed">
                                    <tbody>
                                    <tr>
                                        <td>Number of authors</td>
                                        <td>Frequency</td>
                                    </tr>
                                    {counter_per_authors && counter_per_authors.map(i => (
                                        <tr key={i[0]}>
                                            <td>{i[0]}</td>
                                            <td>{i[1]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Waiting>
                </Content>
            </PrivatePage>
        );
    }
}

export default PaidDocumentsReportPage;